<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://inallar.com.tr/"
        target="_blank"
      >İnallar Otomotiv </b-link>
      <span class="d-none d-sm-inline-block"> Tüm hakları saklıdır.</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <b-link
        class="ml-25"
        href="http://code64.com.tr/"
        target="_blank"
      >CODE64 Yazılım</b-link>
      <feather-icon
        icon="CodeIcon"
        class="text-primary stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
