<template>
  <div>
    <hr>
    <b-button
      block
      variant="flat-primary"
      @click="logout"
    >
      <div class="w-100 text-left d-flex justify-content-between align-items-center">
        <div v-if="textStatus">
          <div>{{ userData.username }}</div>
          <div class="font-small-2">
            {{ userData.user_type }}
          </div>
        </div>
        <div>
          <FeatherIcon
            icon="LogOutIcon"
            size="18"
          />
        </div>
      </div>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
  },
  props: {
    textStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
